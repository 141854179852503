import Markdown from 'markdown-to-jsx';

export const MarkdownViewer = ({ text, className }: { className?: string; text?: string }) => {
  return (
    <Markdown
      className={`markdown-viewer ${className}`}
      style={{ whiteSpace: 'pre-wrap' }}
      options={{ overrides: { a: Anchor } }}
    >
      {text || ''}
    </Markdown>
  );
};

function Anchor({ href, title, children, className }: any) {
  return (
    <a href={href} title={title} target="_blank" rel="noreferrer" className={className}>
      {children}
    </a>
  );
}
